<template>
  <div class="box">
    <div class="headline">题库管理</div>
    <div class="second_line">6.1题库维护</div>
    <span class="text">
      进入题库维护界面，【题库管理】→【题库维护】，如下图
    </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/41.png')"
      :preview-src-list="[require('../../assets/Bankimg/41.png')]"
    >
    </el-image>
    <span class="text">
      该界面是统计所有公司的题库信息，以及统计一个总题数和总文件数。
    </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/42.png')"
      :preview-src-list="[require('../../assets/Bankimg/42.png')]"
    >
    </el-image>
    <span class="text">
      然后选择相应公司部门等信息，点击“搜索”，则会统计该公司的所有文件信息，可以按需根据文件删除该文件下的所有题库信息，点击某一行的“问题数量”，进入如下图：
    </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/43.png')"
      :preview-src-list="[require('../../assets/Bankimg/43.png')]"
    >
    </el-image>
    <span class="text">
      在该界面中，可以勾选要删除的题目进行删除操作，也可导出题库，在Excel中修改，然后在题库维护中导入该Excel可完成对该文件题库修改的操作，还可以直接在该界面操作，修改想要修改的题库，点击“保存”即可。
    </span>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      srcList: "require('../../assets/Bankimg/1.jpg')",
    };
  },
};
</script>
  
  <style  lang="less" scoped>
.box {
  padding-left: 40px;
  .headline {
    font-size: 28px;
    color: #1f2d3d;
    padding-top: 28px;
    line-height: 45px;
    padding-left: 0px ;
  }
  .second_line {
    font-size: 24px;
    line-height: 32px;
    padding: 24px 0 12px;
  }
  .text {
    color: #141e31;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 8px;
  }
  img {
  }
}
</style>